export const toolsList = [
    "CSharp",
    "C",
    "CPlusPlus",
    "Java",
    "Python",
    "JavaScript",
    "TypeScript",
    "HTML5",
    "CSS3",
    "React",
    "Nodejs",
    "Bash",
    "Git",
    "mysql",
    "Docker",
    "Dot-NET",
];
export const conceptsList = [
    "Artificial Intelligence",
    "Systems Programming",
    "Computer Architecture",
    "Networking",
    "Object-Oriented Programming (OOP)",
];

